import React, {useContext, useState} from 'react'
import './style.scss'
import ApolloContext from '../../../../../context/ApolloContext'
import { Link } from 'gatsby'

import { useInView } from 'react-intersection-observer'
import Intro from '../../../../../animations/Intro'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBaby } from '@fortawesome/free-solid-svg-icons'


function CustomBlock({ fields: field }) {
	const [io, ioInView] = useInView({ triggerOnce: true })

    const apollo = useContext(ApolloContext)
    const hasNextPage = apollo.data?.NewsFeatured.pageInfo.hasNextPage

    const posts = apollo.data?.NewsFeatured.nodes.map((node, i) => {
			const d = new Date(node.date)

			const categories = node.categories.nodes.map((node,i) => {
				if (i === 0) {
					return node.name
				}
				return ', ' + node.name
			})

			return (
				<Intro visible={ioInView} in={{fadeInBottom: 500}} delayIn={i % 5 * 125} mounted={true} stay={true} className={`span-3 span-6-tablet span-12-mobile single-post item-${i}`} key={node.id}>
					<Link to={`/blog${node.uri}`} className="single-post-link">
						<div className="categories text-right">{categories}</div>
						<div className="img-container">
							<div className="img-wrapper aspect-ratio">
								<img srcSet={node.featuredImage?.node.srcSet} className="img-cover"/>
							</div>
						</div>
						<div className="box fs-85">
							<h3>{node.title}</h3>
						</div>
						<div className="box-bottom text-right font2">
							{d.toLocaleDateString([], {day: '2-digit', month:'2-digit', year: '2-digit'})}
						</div>
					</Link>
				</Intro>
			)
    })


	return (
		<div ref={io} className="grid-12 master-wrap">

			<Intro visible={true} in={{ fade: 200 }} delayIn={0} out={{ fade: 200 }} className="tab-contents padd-top">
				{posts}
			</Intro>

			{apollo.isLoading ? 
				<div className="loading"><div className="loader-spinner"><FontAwesomeIcon icon={faBaby} /></div></div> 
			: 
				null
			}

			{hasNextPage ? 
				<a className="button load-more" onClick={apollo.loadMoreF}>SHOW&nbsp;MORE</a>
			:
				null // no more posts
			}

		</div>
	)
}

export default CustomBlock