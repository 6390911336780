import React from 'react'
import './style.scss'

import HeroDetail from './HeroDetail'
import FormDetail from './FormDetail'
import Stitches from './Stitches'
import Map from './Map'
import Team from './Team'
import Leadership from './Leadership'
import Tabs from './Tabs'
import ToSection from './ToSection'
import AllNews from './AllNews'
import AllNewsCompact from './AllNewsCompact'
import AllProjects from './AllProjects'

function ACFCustom(props) {

	// Images	
	const images = props.asset?.filter(obj => obj.fieldGroupName === 'page_Pagebuilder_Sections_Content_Block_Custom_Asset_Image')
		.map((node) => {
			return { img: node.assetImg?.localFile.childImageSharp.gatsbyImageData, id: node.assetId }
		})
	
	// Files	
	const files = props.asset?.filter(obj => obj.fieldGroupName === 'page_Pagebuilder_Sections_Content_Block_Custom_Asset_File')
		.map((node) => {
			return node.assetFile
		})

	// Fields	
	const fields = props.asset?.filter(obj => obj.fieldGroupName === 'page_Pagebuilder_Sections_Content_Block_Custom_Asset_Field')
		.map((node) => {
			return node.assetField
		})

	// WYSIWYGS	
	const wysiwygs = props.asset?.filter(obj => obj.fieldGroupName === 'page_Pagebuilder_Sections_Content_Block_Custom_Asset_Wysiwyg')
		.map((node) => {
			return node.assetWysiwyg
		})

	// Maps	
	const maps = props.asset?.filter(obj => obj.fieldGroupName === 'page_Pagebuilder_Sections_Content_Block_Custom_Asset_Map')
		.map((node) => {
			return node.assetMap
		})


	if (props.fieldGroupName === 'page_Pagebuilder_Sections_Content_Block_Custom' && props.anchor === 'hero-home') {
		return (
			<HeroDetail images={images} files={files} fields={fields} wysiwygs={wysiwygs} maps={maps} />
		)
	}

	if (props.fieldGroupName === 'page_Pagebuilder_Sections_Content_Block_Custom' && props.anchor === 'custom-map') {
		return (
			<Map images={images} files={files} fields={fields} wysiwygs={wysiwygs} maps={maps} />
		)
	}

	if (props.fieldGroupName === 'page_Pagebuilder_Sections_Content_Block_Custom' && props.anchor === 'newsletter') {
		return (
			<FormDetail />
		)
	}

	if (props.fieldGroupName === 'page_Pagebuilder_Sections_Content_Block_Custom' && props.anchor === 'stitches') {
		return (
			<Stitches />
		)
	}

	if (props.fieldGroupName === 'page_Pagebuilder_Sections_Content_Block_Custom' && props.anchor === 'team') {
		return (
			<Team images={images} wysiwygs={wysiwygs} />
		)
	}

	if (props.fieldGroupName === 'page_Pagebuilder_Sections_Content_Block_Custom' && props.anchor === 'leadership') {
		return (
			<Leadership />
		)
	}

	if (props.fieldGroupName === 'page_Pagebuilder_Sections_Content_Block_Custom' && props.anchor === 'partnerships') {
		return (
			<Tabs images={images} files={files} fields={fields} wysiwygs={wysiwygs} maps={maps} />
		)
	}

	if (props.fieldGroupName === 'page_Pagebuilder_Sections_Content_Block_Custom' && props.anchor === 'tosection') {
		return (
			<ToSection fields={fields} />
		)
	}

	if (props.fieldGroupName === 'page_Pagebuilder_Sections_Content_Block_Custom' && props.anchor === 'news') {
		return (
			<AllNews fields={fields} />
		)
	}

	if (props.fieldGroupName === 'page_Pagebuilder_Sections_Content_Block_Custom' && props.anchor === 'news-featured') {
		return (
			<AllNewsCompact fields={fields} />
		)
	}

	if (props.fieldGroupName === 'page_Pagebuilder_Sections_Content_Block_Custom' && props.anchor === 'projects') {
		return (
			<AllProjects fields={fields} />
		)
	}

  return false
}

export default ACFCustom