import React from 'react'
import './style.scss'

import VideoHTML from '../../../Video/HTML'

import Intro from '../../../../animations/Intro'
import { useInView } from 'react-intersection-observer'

function ACFVideo(props) {
	const [io, ioInView] = useInView({ triggerOnce: true })

	// Video Type
	const videoSource = props.videoSource
	const videoIframe = props.videoIframe
	const videoFile = props.videoFile?.localFile.publicURL

	
	// Look if the classes specify if the image is supposed to have decor
	let decor = props.classes?.search('decor')
	//console.log(decor,decor >= 0)

	// Returning Section
  return (
		<>
			{ videoSource === 'file' ?
				<div ref={io}>
					{ videoFile ?
						<Intro visible={ioInView} in={{diagonalBottomRight: 500}} delayIn={500} mounted={true} stay={true} className="x0 t">
							<VideoHTML file={videoFile} />
						</Intro>
					: null }

					{decor && decor >= 0 ?
						<div className="decor-wrap">
							<div className="aspect-ratio">
								<Intro visible={ioInView} in={{diagonalTopLeft: 500}} delayIn={500} mounted={true} stay={true} className="c5 t">
									<div className="decor-inside"></div>
								</Intro>
							</div>
						</div>
					: null }
				</div>
				: null }

				{ videoSource === 'iframe' ?
				<div ref={io}>
				{ videoIframe ?
						<div className='video-inject aspect-ratio' dangerouslySetInnerHTML={{__html: videoIframe}} />
					: null }
				</div>
			: null }
		</>
  )
}

export default ACFVideo