import React from 'react'
import './style.scss'
import { useInView } from 'react-intersection-observer'

import CF7Newsletter from '../../../../Form/CF7/Newsletter'
import Intro from '../../../../../animations/Intro'
import { GatsbyImage } from 'gatsby-plugin-image'

function CustomBlock() {
	const [io, ioInView] = useInView({ triggerOnce: true })

	return (
        <div ref={io} className="p-rebel-mono">
            <div className="leadership">
                <p>Leadership</p>
                <div className="pattern">
                    <div className="pattern-box-wrapper">
                        <div className="pattern-box"></div>
                    </div>
                </div>
            </div>
        </div>
	)
}

export default CustomBlock