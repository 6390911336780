import React, {useContext, useEffect} from 'react'
import './style.scss'
import ApolloContext from '../../../../../context/ApolloContext'
import { Link } from 'gatsby'

import { useInView } from 'react-intersection-observer'
import Intro from '../../../../../animations/Intro'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBaby } from '@fortawesome/free-solid-svg-icons'


function CustomBlock({ fields: field }) {
	const [io, ioInView] = useInView({ triggerOnce: true })
    
    const apollo = useContext(ApolloContext)
    const hasNextPage = apollo.data?.News.pageInfo.hasNextPage


    const posts = apollo.data?.News.nodes.map((node, i) => {
        const d = new Date(node.date)
        return (
            <Intro key={node.id} visible={ioInView} in={{fadeInBottom: 500}} delayIn={i % 10 * 125} mounted={true} stay={true} className={`single-post padd-bottom padd-right item-${i}`}>
                <Link to={`/blog${node.uri}`} className="single-post-link">
                    <div className="box-top text-right font2">
                        {d.toLocaleDateString([], {day: '2-digit', month:'2-digit', year: '2-digit'})}
                    </div>
                    <div className="img-container">
                        <div className="img-wrapper aspect-ratio">
                            <img srcSet={node.featuredImage?.node.srcSet} className="img-cover"/>
                        </div>
                    </div>
                    <div className="box fs-85">
                        <h3>{node.title}</h3>
                    </div>
                </Link>
            </Intro>
        )
    })


	return (
		<div ref={io} className="grid-12 master-wrap">

			<Intro switch={ioInView} in={{ fade: 200 }} delayIn={0} out={{ fade: 200 }} className="tab-contents padd-top padd-left">
                {posts}
			</Intro>

			{apollo.isLoading ? 
				<div className="loading"><div className="loader-spinner"><FontAwesomeIcon icon={faBaby} /></div></div> 
			: 
				null
			}

            {hasNextPage ? 
                <button className="button load-more" onClick={apollo.loadMore}>SHOW&nbsp;MORE</button>
            :
                null // no more posts
            }

		</div>
	)
}

export default CustomBlock