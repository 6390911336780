import React from 'react'
import './style.scss'
import { useInView } from 'react-intersection-observer'

import Intro from '../../../../animations/Intro'

function ACFWYSIWYG(props) {
	const [io, ioInView] = useInView({ triggerOnce: true })
	const _in = props.in ? props.in : { fade: 500 }

	// Wysiwyg
	const wysiwyg = props.wysiwyg

	// Look if the classes specify if the image is supposed to have decor
	let decor = props.classes?.search('decor')

	// Returning Section
  return (
		<>
			{ wysiwyg ?
				<div ref={io} className={`attached-to-${props.fieldGroupName}`}>
					<Intro visible={ioInView} in={_in} delayIn={200} mounted={true} stay={true} className="">
						<div className={`wysiwyg-inject animated`}
							dangerouslySetInnerHTML={{ __html: wysiwyg }}
						/>
					</Intro>
					{decor >= 0 ?
						<div className="decor-wrap">
							<Intro visible={ioInView} in={{bg: 3000}} delayIn={500} mounted={true} stay={true} className="c5 t">
								<div className="decor-inside c5"></div>
							</Intro>
						</div>
					: null }
				</div>
			: null }
		</>
		
  )
}

export default ACFWYSIWYG