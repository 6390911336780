import React from 'react'
import './style.scss'
import { useInView } from 'react-intersection-observer'

import Intro from '../../../../../animations/Intro'

function ToSection({images: img, files: file, fields: field, wysiwygs: wysiwyg, maps: map}) {
	const [io, ioInView] = useInView({ triggerOnce: true })

  return (
		<div ref={io} className={`block-tosection`}>
			<Intro visible={ioInView} in={{ blink: 500 }} delayIn={800} mounted={true} stay={true} className="c3 t">
				<a href={`#${field[0]}`} className="decorative-arrow down dark"></a>
			</Intro>
		</div>
  )
}

export default ToSection