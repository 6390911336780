import React from 'react'
import './style.scss'
import { useInView } from 'react-intersection-observer'

import { GatsbyImage } from 'gatsby-plugin-image'
import Intro from '../../../../../animations/Intro'
import Video from '../../../../Video/HTML';


function CustomBlock({images: img, files: file, fields: field, wysiwygs: wysiwyg, maps: map}) {
	const [io, ioInView] = useInView({ triggerOnce: true })
	const _in = { fade: 500 }
	
	// Delay Synchronization	
	const baseDelay = 500
	const stepDelay = 150


	return (
		<>

			{ img[0] ?
				<div ref={io} className="hero-image">
					<div className="positioner">
						<Intro visible={ioInView} in={{ bg: 1000, fade: 500 }} delayIn={baseDelay} mounted={true} stay={true} className={`bg c5 t`}>
							<GatsbyImage image={img[0].img} alt={''} />
						</Intro>
					</div>
				</div>
			: null }

			{ file[0] ?
				<div ref={io} className="hero-image">
					<div className="positioner">
						<Intro visible={ioInView} in={{ bg: 1000, fade: 500 }} delayIn={baseDelay} mounted={true} stay={true} className={`bg c5 t`}>
							<Video file={file[0]?.localFile?.publicURL} mute={true} />
						</Intro>
					</div>
				</div>
			: null }
				
			{ wysiwyg[0] ?
				<div ref={io} className={`block-page_Pagebuilder_Sections_Content_Block_Wysiwyg heading decor`}>
					<Intro visible={ioInView} in={_in} delayIn={baseDelay} mounted={true} stay={true} className="">
						<div className={`wysiwyg-inject animated`}
							dangerouslySetInnerHTML={{ __html: wysiwyg[0] }}
						/>
						<div className="doodle">
							<Intro visible={ioInView} in={{ fade: 500, draw: 1500 }} delayIn={baseDelay + stepDelay * 3} className="with-svg">
								<svg xmlns="http://www.w3.org/2000/svg" width="1838.702" height="209.093" viewBox="0 0 1838.702 209.093">
									<path id="Path_601" data-name="Path 601" d="M1440.349,16298.714c-.869.013-916.771-47.775-945.188-47.7-228.271.581-315.375,50.5-190.005,47.7,104.193-2.322,49.187-36.078,253.776-55.459,73.081-6.923,583.523-15.521,582.292-15.375-4.7.558,1263.156-31.312,376.708-48.322S487.115,16160.9,487.115,16160.9s49.371-.3,193.74-6.256c225.19-9.289,590.039-25.506,657.781-23.349,111.234,3.542,783.443,9.417,169.25,0s-764.635-7.828-904.406-11.3-1216.036-59.213-124.49-7.271,1557.844,59.313,1177.135,48.172-200.151,47.01-200.151,47.01-1130.432-4.641-852.495,0,669.391,17.922,704.365,19.969-1309.193,61.052-687.807,57.2-603.125,4.38-114.661-23.453,915.771,0,915.771,0" transform="translate(5.478 -16090.236)" fill="none" stroke="#031e2e" strokeWidth="1"/>
								</svg>
							</Intro>
						</div>
						<div className="decor-wrap">
							<Intro visible={ioInView} in={{bg: 3000}} delayIn={baseDelay + stepDelay * 3} mounted={true} stay={true} className="c5 t">
								<div className="decor-inside"></div>
							</Intro>
						</div>
					</Intro>
				</div>
			: null }
				
			{ wysiwyg[1] ?
				<div ref={io} className={`aside`}>
					<Intro visible={ioInView} in={_in} delayIn={200} mounted={true} stay={true} className="">
						<div className={`wysiwyg-inject animated`}
							dangerouslySetInnerHTML={{ __html: wysiwyg[1] }}
						/>
					</Intro>
				</div>
			: null }

			
		</>
	)
}

export default CustomBlock