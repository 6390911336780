import React from 'react'
import './style.scss'
import { useInView } from 'react-intersection-observer'

import CF7Newsletter from '../../../../Form/CF7/Newsletter'
import Intro from '../../../../../animations/Intro'
import { GatsbyImage } from 'gatsby-plugin-image'

function CustomBlock({images: img, files: file, fields: field, wysiwygs: wysiwyg, maps: map}) {
	const [io, ioInView] = useInView({ triggerOnce: true })

	return (
        <div ref={io} className="p-rebel-mono">
            <div className="team-member">
                <div className="pic">
                    <div className="aspect-ratio">
                        <Intro visible={ioInView} in={{ bg: 700, fade: 500 }} delayIn={500} mounted={true} stay={true} className={`bg c5 t`}>
                            <GatsbyImage image={img[0].img} alt={''} />
                        </Intro>
                    </div>
                </div>
                <div className="details">
                    <Intro visible={ioInView} in={{ fade: 500 }} delayIn={200} mounted={true} stay={true} className="">
                        <div className={`wysiwyg-inject animated`}
                            dangerouslySetInnerHTML={{ __html: wysiwyg[0] }}
                        />
                    </Intro>
                </div>
            </div>
        </div>
	)
}

export default CustomBlock