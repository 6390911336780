import React, {useContext, useState} from 'react'
import './style.scss'
import ApolloContext from '../../../../../context/ApolloContext'
import { Link } from 'gatsby'

import { useInView } from 'react-intersection-observer'
import Intro from '../../../../../animations/Intro'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBaby } from '@fortawesome/free-solid-svg-icons'

import SmoothScroll from 'smooth-scroll/dist/smooth-scroll'


function CustomBlock({ fields: field }) {
	const [io, ioInView] = useInView({ triggerOnce: true })
	const [currentTab, setCurrentTab] = useState(0)

	const apollo = useContext(ApolloContext)
	const hasNextPage = apollo.data?.academics.pageInfo.hasNextPage


	//const featuredImages = img?.filter(node => node.id === 'img')

	// Tabs
	const TabPack = apollo.data?.portfolioTypes?.nodes?.map((node, i) => {
		return (
			<div className={`tab-handle ${currentTab === node.name ? 'current' : ''}`} key={i} onClick={() => onTabChange(node.name)} onKeyDown={() => onTabChange(node.name)} role="button" tabIndex={0}>
				<p>{node.name}</p>
			</div>
		)
	})

	// Tabs Content
	const TabContentPack = apollo.data?.academics?.nodes.map((node, i) => {
		if (currentTab === 0 || !!node.portfolioTypes?.nodes.find(node => node.name === currentTab)) {
			return (
				<Intro visible={ioInView && !apollo.isLoading} in={{ fadeInBottom: 500 }} delayIn={i * 125} mounted={true} stay={true} className={`single-post item-${i}`}  key={node.id}>
					<Link to={node.uri}>
						<div className="img-container">
							<div className="img-wrapper aspect-ratio">
								<img srcSet={node.featuredImage?.node.srcSet} className="img-cover"/>
							</div>
						</div>
						<div className="box fs-85">
							<h3>{node.title}</h3>
							{node.portfolio?.location ?
								<p className="font2 fs-85 uppercase">{node.portfolio.location}</p>
							: null }
						</div>
					</Link>
				</Intro>
			)
		}
		return false
	})

	// On Parent Tab Change
	function onTabChange(i) {
		setCurrentTab(i)

		if (typeof window !== 'undefined') {
			var scroll = new SmoothScroll()
			var anchor = document.getElementById('block-projects-anchor')
			scroll.animateScroll(anchor)
		}
	}


	return (
		<div ref={io} className="grid-12 master-wrap">
			<div id="block-projects-anchor"></div>

			<Intro visible={ioInView && !apollo.isLoading} in={{ fade: 500 }} delayIn={200} mounted={true} stay={true} className={`tab-handles-wrap`}>
				<div className="tab-handles c2 font2 fs-85">
					<p className="uppercase fs-85">// Filter:</p>
					<div className={`tab-handle ${currentTab === 0 ? 'current' : ''}`} onClick={() => onTabChange(0)} onKeyDown={() => onTabChange(0)} role="button" tabIndex={0}>	
						<p>All</p>
					</div>
					{TabPack}
				</div>
			</Intro>

			<Intro switch={currentTab} in={{ fade: 200 }} delayIn={0} out={{ fade: 200 }} className="tab-contents padd">
				{TabContentPack}
			</Intro>

			{apollo.isLoading ? 
				<div className="loading"><div className="loader-spinner"><FontAwesomeIcon icon={faBaby} /></div></div> 
			: 
				null
			}

		</div>
	)
}

export default CustomBlock