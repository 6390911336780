import React from 'react'
import './style.scss'
import { useInView } from 'react-intersection-observer'

import CF7Newsletter from '../../../../Form/CF7/Newsletter'
import Intro from '../../../../../animations/Intro'

function CustomBlock({ fields: field }) {
	const [io, ioInView] = useInView({ triggerOnce: true })

	return (
        <div ref={io} className="stitches">
            <Intro visible={ioInView} in={{ fade: 1500 }} delayIn={1000} mounted={true} stay={true} className={``}>
                <div className="stitch-left c5"></div>
                <div className="stitch-center c5"></div>
                <div className="stitch-right c5"></div>
            </Intro>
        </div>
	)
}

export default CustomBlock