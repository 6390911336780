import React, {useState} from 'react'
import './style.scss'
import { useInView } from 'react-intersection-observer'

import { GatsbyImage } from 'gatsby-plugin-image'

import Intro from '../../../../../animations/Intro'

function CustomBlock({images: img, files: file, fields: field, wysiwygs: wysiwyg, maps: map}) {
	const [io, ioInView] = useInView({ triggerOnce: true })
	const [currentTab, setCurrentTab] = useState(0)

	//console.log(img[0])

	const logos = img?.filter(node => node.id === 'logo')
	const featuredImages = img?.filter(node => node.id === 'img')

	// Tabs
	const TabPack = logos?.map((node, i) => {
		return (
			<div className={`tab-handle ${node.tabHandle?.classes} ${i === currentTab ? 'current' : ''}`} key={i} onClick={() => onTabChange(i)} onKeyDown={() => onTabChange(i)} role="button" tabIndex={0}>
				<Intro visible={ioInView} in={{ fade: 500 }} delayIn={200} mounted={true} stay={true} className={`bgless c5 t`}>
					<GatsbyImage image={logos[i].img} alt={''} />
				</Intro>
			</div>
		)
	})

	// Tabs Content
	const TabContentPack = img?.map((node, i) => {
		if (i === currentTab) {
			return (
				<div key={i} className={`tab-content flex-12 ${node.tabContent?.classes}`}>
					<div className="span-6 span-12-tablet padd left">
						<Intro visible={ioInView} in={{ fade: 500 }} delayIn={200} mounted={true} stay={true} className={`logo c5 t`}>
							<GatsbyImage image={logos[i].img} alt={''} />
						</Intro>
						<Intro visible={ioInView} in={{ fade: 500 }} delayIn={200} mounted={true} stay={true} className={`text c5 t`}>
							<div className={`wysiwyg-inject animated fs-85`}
								dangerouslySetInnerHTML={{ __html: wysiwyg[i] }}
							/>
						</Intro>
					</div>
					<div className="span-6 span-12-tablet right">
						<Intro visible={ioInView} in={{ fade: 500 }} delayIn={200} mounted={true} stay={true} className={`span-6 bg c5 t`}>
							<GatsbyImage image={featuredImages[i].img} alt={''} />
						</Intro>
					</div>
					{/* {img?.length - 1 > i ?
						<div className="tab-control-next-positioner hover-trigger"onClick={() => onTabChange(i + 1)} onKeyDown={() => onTabChange(i + 1)} role="button" tabIndex={0}><span className="text">Next</span><span className="icon"></span></div>
					: null } */}
				</div>
			)
		}
		return false
	})

	// On Parent Tab Change
	function onTabChange(i) {
		setCurrentTab(i)
	}

  return (
				<>
					<div ref={io} className={`tab-handles flex`}>
						{TabPack}
					</div>
					<Intro switch={currentTab} in={{ fade: 200 }} delayIn={0} out={{ fade: 200 }} className="tab-contents padd padd-right-off padd-left-off padd-bottom-off">
						{TabContentPack}
					</Intro>
				</>
  )
}

export default CustomBlock